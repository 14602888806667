import { authReducer } from './authReducer'
import { uiReducer } from './uiReducer'
import { possesReducer } from './possesReducer'
import { profilesReducer } from './profilesReducer'
import { invitesReducer } from './invitesReducer'
import { meReducer } from './meReducer'

const mainReducer = (state, action) => {
  let newState = {
    ui: uiReducer(state.ui, action),
    auth: authReducer(state.auth, action),
    me: meReducer(state.me, action),
    posses: possesReducer(state.posses, action),
    profiles: profilesReducer(state.profiles, action),
    invites: invitesReducer(state.invites, action),
  }
  if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_SUPPRESS_STATE_LOGS !== 'true') {
    console.group(`%cACTION: ${action.type}`, 'color: blue; font-size: 0.8rem')
    console.log('ACTION: ', action)
    console.log('PREVIOUS STATE: ', state)
    console.log('CURRENT STATE: ', newState)
    console.groupEnd()
  }
  return newState
}

export default mainReducer
